import React from "react";
import "./SearchStyles.css";

import TigersEys from "../../assets/img1.jpeg";
import Vivianite from "../../assets/img2.jpeg";
import Chrysoprase from "../../assets/img3.jpeg";
import Trolleite from "../../assets/img4.jpeg";
import Vanadinit from "../../assets/vanadinit.jpg";

import Svabit1 from "../../assets/uv-img1.jpg";
import Hyalite from "../../assets/uv-img2.jpg";
import Svabit2 from "../../assets/uv-img3.jpg";
import Margarosanit from "../../assets/uv-img4.jpg";
import Willemit from "../../assets/uv-img5.jpg";

export const Search = () => {
  return (
    <div name="book" className="search">
      <div className="container">
        <div className="left">
          <h2>Are you fascinated by UV Minerals?</h2>
          <p>
            Join us for an <b>SPECIAL UV DAY at January 20th,</b>where all our
            UV minerals take center stage. This is the perfect opportunity for
            enthusiasts usually shop online to physicall examine the quality of
            our minerals. Don't miss this chance to see, touch, and truly
            appreciate the fine quality of our minerals - Everything for sure at
            a better price than any other dealer and available to pick up right
            there! Benefit from our expertise throughout <br />
            the event and ensure you're making the best choice for your
            collection
          </p>
          <div className="img-container">
            <img
              className="span-3 image-grid-row-2"
              src={Svabit1}
              alt="Swabit from Schweden"
            />
            <img src={Hyalite} alt="Hyalite from Namibia" />
            <img src={Svabit2} alt="Svabit from Schweden " />
            <img src={Margarosanit} alt="Margarosanit from Sweden" />
            <img src={Willemit} alt="from Zambia" />
          </div>
          <div className="werbe-text">
            <h2>VIST OUR BOOTHS AT THE TUCSON GEM & MINERAL SHOW</h2>
            <p>
              <b>Mikon Mineralienkontor</b> and <b>Shannon Family Minerals</b>
              boast the largest variety of rare, unique specimens, minerals by
              the flat, rough rocks, and accessories. We're bringing our
              extensive collection to the <b>Tucson Gem & Mineral Show 2024</b>
              again! Join us at our exhibition booths at both the TEP Gem &
              Mineral Show an in Mineral City. We have
              <b>more than 100 metric tons of rough rock</b> in stock, along
              with
              <b>pallet loads of minerals by the flat, individual specimens</b>
              from our latest field trips all over the world, as our full range
              of
              <b>
                supplies, jewellery, fossils, equipment, optics, and so much
                more.
              </b>
              All guaranteed to be at a better price than any other dealer and
              available for immediate pick-up right there! We're also presenting
              our
              <b>
                freshly collected minerals from Charcas and other places in
                Northern Mexico,
              </b>
              direct from the cleaning gun! Be the first to see them! We're
              featuring other primarily European dealer at that location as
              well. After the show, we offer storage facilities too. Dive deeper
              into the world of minerals with Mikon. We're not just about
              business; we're about passion, knowledge, and a commitment to
              providing the best to our customers. Visit our booths at the
              Tucson Gem & Mineral Show, and let's explore the wonders of the
              mineral world together!
            </p>
          </div>
          <div className="search-col-2">
            <div className="box">
              <div>
                <h3>KINO GEM & MINERALE SHOW TUCSON/USA</h3>
                <p>
                  KINO SPORTS COMPLEX TENTS 2500 E.AJO WAY, TUCSON, ARIZONA
                  85713
                </p>
                <p>January 26th - February 2th 2024 9AM-6PM</p>
              </div>
            </div>
            <div className="box">
              <div>
                <h3>
                  THE ROUGH ROCK <br /> WAREHOUSE SHOW
                </h3>
                <p>327 E. LESTER STREET, TUCSON, ARIZONA 85705</p>
                <p>January 16th - January 21th 2024 10AM-6PM</p>
              </div>
            </div>
            <div className="box">
              <div>
                <h3>
                  MINERAL CITY SHOW <br />
                  SHANNON FAMILY MINERALS
                </h3>
                <p>525 W PLATA STREET, TUCSON, ARIZONA 85705</p>
                <p>BUILDING D ROOMS 23 & 25</p>
                <p>January 26th - February 10th 2024 9AM-6PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="promo">
            <h4 className="save">UV-EVENING</h4>
            <p className="timer">
              <p>327 E. LESTER STREET, TUCSON, ARIZONA 85705</p>
              January 16th - 21St January 2024 10AM-6PM <br />
              UV-evening on January 20th 8AM-6PM
            </p>
          </div>
          <div className="img-container">
            <img
              className="span-3 image-grid-row-2"
              src={TigersEys}
              alt="Tigers Eys from South Africa"
            />
            <img src={Vivianite} alt="Vivianite from Bolivia" />
            <img
              src={Chrysoprase}
              alt="Chrysoprase from Sulawesi, Indoenesia "
            />
            <img src={Trolleite} alt="Trolleitefrom Sweden" />
            <img src={Vanadinit} alt="from Marokko" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
