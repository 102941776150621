import React from "react";
import "./FooterStyles.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="top">
          <h3>MIKON MINERALIENKONTOR</h3>
        </div>
        <div className="bottom">
          <div className="left">
            <p>Cobyright® by Sandra Reichow </p>
          </div>
          <div className="right">
            <ul>
              <li>
                <a href="https://mikon-online.com/mikon_de/kontakt">Contact</a>
              </li>
              <li>
                <a href="https://mikon-online.com/mikon_de/impressum">
                  Imprint
                </a>
              </li>
              <li>
                <a href="https://mikon-online.com/mikon_de/widerrufsrecht">
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
