import React from "react";
import "./MineralsStyles.css";

import Messe from "../../assets/img15.jpeg";
import Karte from "../../assets/karte-messe.jpeg";

function Minerals() {
  return (
    <div name="minerals" className="minerals">
      <div className="container">
        <h1>
          Mikon Mineralienkontor
          <h1>&</h1>
          Shannon Family Minerals
        </h1>
        <div className="messe-content">
          <p>
            Warenhouse SALE 16th - 21th JANUARY - 327E Lester Street, TUCSON
            <br />
            This year, we're offering you an exclusive sneak peek at our grand
            selection
          </p>
        </div>
        <div className="img-container-messe">
          <img
            className="span-3 image-grid-row-2"
            src={Messe}
            alt="Messestand in Tucson"
          />
          <img src={Karte} alt="Google Karte vom Gebäute" />
        </div>
      </div>
    </div>
  );
}
export default Minerals;
