import React from "react";
import "./SelectsStyles.css";

import Wanniglacier from "../../assets/img6.jpeg";
import Emmelberg from "../../assets/img7.jpeg";
import Aris from "../../assets/img8.jpeg";
import Kamsdorf from "../../assets/img9.jpeg";
import Eclogite from "../../assets/img10.jpeg";
import Bellerberg from "../../assets/img11.jpeg";
import SelectsImg from "../SelectsImg/SelectsImg";

export const Selects = () => {
  return (
    <div className="img-conatiner">
      <div name="views" className="selects">
        <div className="container">
          <SelectsImg bgImg={Wanniglacier} text="Wanniglacier, Switzerland" />
          <SelectsImg bgImg={Emmelberg} text="Emmelberg, Eifel, Germany" />
          <SelectsImg bgImg={Aris} text="Aris, Windhoek, Namibia" />
          <SelectsImg bgImg={Kamsdorf} text="Kamsdorf, Thuringia, Germany" />
          <SelectsImg bgImg={Eclogite} text="Fichtelberg, Germany" />
          <SelectsImg bgImg={Bellerberg} text="Bellerberg, Eifel, Germany" />
        </div>
      </div>
    </div>
  );
};

export default Selects;
