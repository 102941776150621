import React, { useState } from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

import { Link } from "react-scroll";

import "./NavbarStyles.css";

function Navbar() {
  const [nav, setNav] = useState(false);
  const handleNav = () => setNav(!nav);

  return (
    <div name="home" className={nav ? "navbar navbar-bg" : "navbar"}>
      <div className={nav ? "logo dark" : "logo"}>
        <a href="https://mikon-online.com/mikon_en/">
          <h2>MIKON MINERALIENKONTOR</h2>
        </a>
      </div>
      <ul className="nav-menu">
        <Link to="home" smooth={true} duration={500}>
          <li>HOME</li>
        </Link>
        <Link to="minerals" smooth={true} duration={500}>
          <li>WAREHOUSE SALE</li>
        </Link>

        <Link to="search" smooth={true} duration={500}>
          <li>UV DAY</li>
        </Link>
        <Link to="carousel" smooth={true} duration={500}>
          <li>SHOWBOOTH</li>
        </Link>
        <Link to="views" smooth={true} duration={500}>
          <li>MINERALS</li>
        </Link>
      </ul>
      <div className="hamburger" onClick={handleNav}>
        {!nav ? (
          <HiOutlineMenuAlt4 className="icon" />
        ) : (
          <AiOutlineClose style={{ color: "#000" }} className="icon" />
        )}
      </div>
      <div className={nav ? "mobile-menu active" : "mobile-menu"}>
        <ul className="mobile-nav">
          <Link to="home" smooth={true} duration={500}>
            <li>HOME</li>
          </Link>
          <Link to="minerals" smooth={true} duration={500}>
            <li>WAREHOUSE SALE</li>
          </Link>
          <Link to="carousel" smooth={true} duration={500}>
            <li>UV DAY</li>
          </Link>
          <Link to="search" smooth={true} duration={500}>
            <li>SHOWBOOTH</li>
          </Link>
          <Link to="views" smooth={true} duration={500}>
            <li>MINERALS</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
